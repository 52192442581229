<template>
  <div class="fluid" id="countries">
    <b-card style="width: 80%; margin: 0 auto; margin-bottom: 20px">
      <div class="row d-flex align-items-center">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-6">
              <label for="" class="mx-1 label-font">Search:</label>
              <b-form-group>
                  <b-form-input v-debounce:300="getCountries" v-model="query.keyword" type="text" placeholder="Search"
                    class="search-input bg-light" />
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-md-3 p-0">
              <b-button v-if="$can('create', 'Country')" v-b-modal.modal-prevent-closing class="btn mt-1 mt-lg-0 add-btn d-block ml-auto">
                <div class="d-flex flex-row align-items-center">
                  <span class="pr-1"> Add New </span>
                  <span>
                    <img src="@/assets/images/icons/add-icon.png" alt="add icon" height="20" width="20" />
                  </span>
                </div>
              </b-button>
            </div>
          </div>
        </b-card>
        <div style="width: 80%; margin: 0 auto">
          <b-table :items="countries" :fields="parentFields" show-empty responsive striped hover class="bg-white cursor-icon">
            <template #cell(id)="row">
              <div :class="`p-1 ${row.item.status === 1 ? 'active-success' : 'active-danger'
                }`">
                <p>
                  {{ row.index + 1 }}
                </p>
              </div>
            </template>
            <template #cell(name)="row">
              <p>
                {{ row.item.name }}
              </p>
            </template>
            <template #cell(action)="row">
              <div>
                <button v-if="$can('update', 'Country')" v-b-modal.modal-prevent-closing @click.prevent="getCountry(row.item.id)"
                class="btn btn-info d-block ml-auto">
                Edit
              </button>
            </div>
          </template>
        </b-table>
        <b-pagination class="mt-3 p-1 d-flex justify-content-end bg-white" v-if="total > 0" v-model="query.page"
          :total-rows="total" :per-page="query.per_page" aria-controls="my-table" @change="getCountries($event)" pills
          size="lg" page-class="info"></b-pagination>
      </div>

      <b-modal id="modal-prevent-closing" ref="modal" title="Create New Country" @show="resetModal" @hidden="resetModal"
        @ok="handleOk" :ok-title="country.id ? 'Update' : 'Save'">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group label="Name" label-for="name-input" invalid-feedback="Name is required" :state="nameState">
            <b-form-input id="name-input" v-model="country.name" :state="nameState" required class="mb-2 bg-light"
              placeholder="Enter country name"></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
BButton,
BCard,
BCol,
BFormGroup,
BFormInput,
BPagination,
BRow,
BTable
} from "bootstrap-vue";

import { toast } from "@/utils/toast";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
  },

  data() {
    return {
      homeLink: false,
      countries: [],
      nameState: null,
      total: 0,
      country: {
        name: "",
      },
      query: {
        keyword: "",
        page: 1,
        per_page: 20,
      },
      parentFields: [
        {
          key: "id",
          label: "id",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
          label: "name",
        },
        {
          key: "action",
          label: "action",
        },
      ],
    };
  },
  created() {
    this.getCountries();
  },
  methods: {
    getCountries(e) {
      if (e) {
        this.query.page = e;
      }
      this.$store
        .dispatch("country/getCountries", this.query)
        .then((response) => {
          this.countries = response.data.data;
          this.total = response.data.total;
        });
    },
    async getCountry(id) {
      let response = await this.$store.dispatch("country/getCountry", id);
      this.country = response.data;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.nameState = null;
      this.country = {
        name: "",
      };
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.country.id) {
        this.updateCountry();
      } else {
        this.createCountry();
      }
    },
    createCountry() {
      this.$store
        .dispatch("country/createCountry", this.country)
        .then((response) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.getCountries();
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Country Create Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    },
    updateCountry() {
      this.$store
        .dispatch("country/updateCountry", this.country)
        .then((response) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.getCountries();
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Country Update Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

th:last-child {
  text-align: end !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
